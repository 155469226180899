import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import SidebarComponent from "../../components/dashboard/Sidebar";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../components/BaseUrl";
import CircularProgress from "@mui/material/CircularProgress";
import { AddComma } from "../../utils/capitalize";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";

const NewPool = () => {
  const { admin } = useContext(AuthContext);
  const [accounts, setAccounts] = useState("");
  const [loading, setLoading] = useState(false);
  const [markdown, setMarkdown] = useState("");
  const [doc, setDoc] = useState("");
  const [fields, setFields] = useState([{ coin: "", percentage: "" }]);
  const [defaultPool, setDefaultPool] = useState({
    name: "",
    manager: "",
    valueManaged: "",
    lifetimeReturn: "",
    performance: "",
    riskFactor: "",
    investors: "",
    oneMonthReturn: "",
    threeMonthsReturn: "",
    sixMonthsReturn: "",
    yearlyReturn: "",
    active: true,
    accountType: [],
    managerHolding: null,
    category: "",
    strategyWallet: "",
    weeklyReturn: "",
    dailyReturn: "",
    managementFeesEarned: "",
    totalLockedValue: "",
    strategyProsAndCons: "",
    details: "",
    managementFee: "",
  });

  const categories = [
    "Defi",
    "Market Yield",
    "Hedging",
    "Arbitrage",
    "Rebalancing",
  ];

  const handleEditorChange = ({ text }) => {
    setMarkdown(text);
  };
  function handlePortfolioInputChange(index, event) {
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
  }

  function handleAddField() {
    setFields([...fields, { coin: "", percentage: "" }]);
  }

  function handleRemoveField(index) {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault(); // prevent tab from moving focus to next element
      const account = accounts.charAt(0).toUpperCase() + accounts.slice(1);

      setDefaultPool({
        ...defaultPool,
        accountType: [...defaultPool.accountType, account.trim()],
      });
      setAccounts("");
    }
  };
  const handleInputChange = (event) => {
    setAccounts(event.target.value);
  };

  async function newPool(e) {
    setLoading(true);
    e.preventDefault();
    const data = {
      name: defaultPool.name,
      manager: defaultPool.manager,
      file: doc,
      valueManaged: AddComma(defaultPool.valueManaged),
      lifetimeReturn: defaultPool.lifetimeReturn,
      performance: defaultPool.performance,
      riskFactor: defaultPool.riskFactor,
      accountType: defaultPool.accountType,
      investors: defaultPool.investors,
      oneMonthReturn: defaultPool.oneMonthReturn,
      threeMonthsReturn: defaultPool.threeMonthsReturn,
      sixMonthsReturn: defaultPool.sixMonthsReturn,
      yearlyReturn: defaultPool.yearlyReturn,
      about: markdown,
      active: defaultPool.active,
      managerHolding: defaultPool.managerHolding,
      category: defaultPool.category,
      strategyWallet: defaultPool.strategyWallet,
      portfolio: fields,
      weeklyReturn: defaultPool.weeklyReturn,
      dailyReturn: defaultPool.dailyReturn,
      managementFeesEarned: AddComma(defaultPool.managementFeesEarned),
      totalLockedValue: AddComma(defaultPool.totalLockedValue),
      strategyProsAndCons: defaultPool.strategyProsAndCons,
      details: defaultPool.details,
      managementFee: defaultPool.managementFee,
    };
    const formdata = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key === "accountType" || key === "portfolio") {
        formdata.append(key, JSON.stringify(value));
      } else {
        formdata.append(key, value);
      }
    }
    fetch(`${BASE_URL}/admin/pool/new`, {
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        alert("Pool created successfully");
        setLoading(false);
        console.log(res);
      })
      .catch((error) => {
        console.log(error.response.data);
        alert("An error occured, please try again later");
        setLoading(false);
      });
  }

  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          <div>
            <div>
              <h1 className="text-gray-400 text-xl font-medium">
                New Strategy
              </h1>
            </div>
            <form onSubmit={newPool}>
              <div className="md:grid grid-cols-3 gap-10 mt-10">
                <div>
                  <label htmlFor="name" className="text-gray-300 text-sm">
                    Name
                  </label>
                  <input
                    name="name"
                    onChange={(e) =>
                      setDefaultPool({ ...defaultPool, name: e.target.value })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="manager" className="text-gray-300 text-sm">
                    Manager
                  </label>
                  <input
                    name="manager"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        manager: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="image" className="text-gray-300 text-sm">
                    Manager Image
                  </label>
                  <input
                    name="image"
                    onChange={(e) => {
                      setDoc(e.target.files[0]);
                    }}
                    type="file"
                    required
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="managerHolding"
                    className="text-gray-300 text-sm"
                  >
                    Manager Holding (%)
                  </label>
                  <input
                    type="number"
                    name="managerHolding"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        managerHolding: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="category" className="text-gray-300 text-sm">
                    Category
                  </label>
                  <select
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        category: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  >
                    <option>Select Strategy Category</option>
                    {categories.map((category) => {
                      console.log(category);
                      return <option>{category.toString()}</option>;
                    })}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="strategyWallet"
                    className="text-gray-300 text-sm"
                  >
                    Strategy Wallet Address
                  </label>
                  <input
                    type="text"
                    name="strategyWallet"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        strategyWallet: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="managementFee"
                    className="text-gray-300 text-sm"
                  >
                    Management Fee
                  </label>
                  <input
                    type="text"
                    name="managementFee"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        managementFee: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="strategyProsAndCons"
                    className="text-gray-300 text-sm"
                  >
                    Strategy Pros and Cons Link
                  </label>
                  <input
                    type="text"
                    name="strategyProsAndCons"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        strategyProsAndCons: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="details" className="text-gray-300 text-sm">
                    Strategy details link
                  </label>
                  <input
                    type="text"
                    name="details"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        details: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="managementFeesEarned"
                    className="text-gray-300 text-sm"
                  >
                    Management Fees Earned
                  </label>
                  <input
                    type="text"
                    name="managementFeesEarned"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        managementFeesEarned: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="totalLockedValue"
                    className="text-gray-300 text-sm"
                  >
                    Total Locked Value
                  </label>
                  <input
                    type="text"
                    name="totalLockedValue"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        totalLockedValue: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="valueManaged"
                    className="text-gray-300 text-sm"
                  >
                    Value Managed
                  </label>
                  <input
                    type="number"
                    name="valueManaged"
                    value={defaultPool.valueManaged}
                    onChange={(e) => {
                      setDefaultPool({
                        ...defaultPool,
                        valueManaged: e.target.value,
                      });
                    }}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="performance"
                    className="text-gray-300 text-sm"
                  >
                    Performance
                  </label>
                  <input
                    type="number"
                    name="performance"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        performance: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="riskFactor" className="text-gray-300 text-sm">
                    Risk Factor
                  </label>
                  <input
                    name="riskFactor"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        riskFactor: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="investors" className="text-gray-300 text-sm">
                    Investors
                  </label>
                  <input
                    type="number"
                    name="investors"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        investors: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="lifetimeReturn"
                    className="text-gray-300 text-sm"
                  >
                    Lifetime Return
                  </label>
                  <input
                    type="text"
                    name="lifetimeReturn"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        lifetimeReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="dailyReturn"
                    className="text-gray-300 text-sm"
                  >
                    Daily Return
                  </label>
                  <input
                    type="text"
                    name="dailyReturn"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        dailyReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="weeklyReturn"
                    className="text-gray-300 text-sm"
                  >
                    Weekly Return
                  </label>
                  <input
                    type="text"
                    name="weeklyReturn"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        weeklyReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="oneMonthReturn"
                    className="text-gray-300 text-sm"
                  >
                    One Month Return
                  </label>
                  <input
                    type="text"
                    name="oneMonthReturn"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        oneMonthReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="threeMonthsReturn"
                    className="text-gray-300 text-sm"
                  >
                    Three Months Return
                  </label>
                  <input
                    type="text"
                    name="threeMonthsReturn"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        threeMonthsReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="sixMonthsReturn"
                    className="text-gray-300 text-sm"
                  >
                    Six Months Return
                  </label>
                  <input
                    type="text"
                    name="sixMonthsReturn"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        sixMonthsReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="yearlyReturn"
                    className="text-gray-300 text-sm"
                  >
                    Yearly Return
                  </label>
                  <input
                    type="text"
                    name="yearlyReturn"
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        yearlyReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="portfolio" className="text-gray-300 text-sm">
                    Portfolio
                  </label>
                  <form>
                    {fields.map((field, index) => (
                      <div
                        key={index}
                        className="space-x-2 flex items-center py-4 w-full"
                      >
                        <div>
                          <p className="text-gray-300 text-sm">Coin</p>
                          <input
                            type="text"
                            name="coin"
                            value={field.coin}
                            onChange={(e) =>
                              handlePortfolioInputChange(index, e)
                            }
                            className="bg-[#181b20] py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                          />
                        </div>
                        <div>
                          <p className="text-gray-300 text-sm">Percentage</p>
                          <input
                            type="text"
                            name="percentage"
                            value={field.percentage}
                            onChange={(e) =>
                              handlePortfolioInputChange(index, e)
                            }
                            className="bg-[#181b20] py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                          />
                        </div>
                        <button
                          className="bg-red-400 text-white h-8 px-4 text-sm rounded-md"
                          type="button"
                          onClick={() => handleRemoveField(index)}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      className="bg-yellow-400 text-white px-4 rounded-md text-sm py-1"
                      type="button"
                      onClick={handleAddField}
                    >
                      Add coin
                    </button>
                  </form>
                </div>
                <div>
                  <label
                    htmlFor="accountType"
                    className="text-gray-300 text-sm"
                  >
                    Account types{" "}
                    <p className="text-xs">Separate with the tab button</p>
                  </label>
                  <input
                    value={accounts}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    // ref={prevInputRef}
                    name="accountType"
                    className="bg-[#181b20] capitalize w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                  <div className="flex items-center space-x-3">
                    {defaultPool.accountType.length > 0 &&
                      defaultPool.accountType.map((account, index) => {
                        return (
                          <div
                            onClick={() => {
                              defaultPool.accountType.splice(index, 1);
                              console.log(defaultPool.accountType);
                            }}
                            key={index}
                            className="box"
                          >
                            {account}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <label htmlFor="about" className="text-gray-300 text-sm">
                  About
                </label>
                <MdEditor
                  value={markdown}
                  renderHTML={(text) => MarkdownIt().render(text)}
                  onChange={handleEditorChange}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="md:w-[150px] mt-4 w-full bg-[#b2ffce] px-5 py-2 rounded-md font-medium"
                >
                  {loading ? (
                    <CircularProgress color="inherit" size="25px" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPool;
