import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import SidebarComponent from "../../components/dashboard/Sidebar";
import Loader from "../../components/Loader";
import axios from "axios";
import { BASE_URL } from "../../components/BaseUrl";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const UserPage = () => {
  const { id } = useParams();
  const { admin } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [name, setName] = useState(user?.name);
  const [phone, setPhone] = useState(user?.phone);
  const [state, setState] = useState(user?.state);
  const [deposits, setDeposits] = useState(user?.deposits);
  const [activeInvestments, setActiveInvestments] = useState(
    user?.activeInvestments
  );
  const [withdrawals, setWithdrawals] = useState(user?.withdrawals);
  const [deposited, setDeposited] = useState(user?.deposited);
  const [withdrawn, setWithdrawn] = useState(user?.withdrawn);
  const [balance, setBalance] = useState(user?.balance);
  const [verification, setVerification] = useState(user?.verified);
  const [earnings, setEarnings] = useState(user?.earnings);
  const [country, setCountry] = useState(user?.country);
  const [tier, setTier] = useState(user?._type);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  async function updateDetails(
    userName,
    userPhone,
    userState,
    userCountry,
    userDeposits,
    userDeposited,
    userWithdrawals,
    userWithdrawn,
    userBalance,
    userActiveInvestments,
    userVerification,
    userEarnings,
    userId,
    userTier
  ) {
    try {
      setLoading(true);
      const res = await axios.post(`${BASE_URL}/admin-auth/update-user`, {
        name: userName,
        country: userCountry,
        state: userState,
        phone: userPhone,
        deposits: userDeposits,
        deposited: userDeposited,
        balance: userBalance,
        withdrawals: userWithdrawals,
        withdrawn: userWithdrawn,
        activeInvestments: userActiveInvestments,
        verification: userVerification,
        earnings: userEarnings,
        id: userId,
        _type: userTier,
      });
      if (res.data.status === "success") {
        setSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setSuccess(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    axios
      .get(`${BASE_URL}/txns/user/${id}`)
      .then((res) => {
        setUser(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          {user && (
            <div>
              <div>
                <h1 className="text-gray-400 text-xl font-medium">Settings</h1>
              </div>
              <form className="md:grid grid-cols-3 gap-10 mt-10">
                <div>
                  <label htmlFor="name" className="text-gray-300 text-sm">
                    Name
                  </label>
                  <input
                    name="name"
                    defaultValue={user.name}
                    onChange={(e) => setName(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="text-gray-300 text-sm">
                    Email
                  </label>
                  <input
                    name="email"
                    defaultValue={user.email}
                    readOnly
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="type" className="text-gray-300 text-sm">
                    Tier
                  </label>
                  <input
                    name="type"
                    defaultValue={user._type}
                    onChange={(e) => setTier(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="text-gray-300 text-sm">
                    Phone
                  </label>
                  <input
                    name="phone"
                    defaultValue={user.phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="country" className="text-gray-300 text-sm">
                    Country
                  </label>
                  <input
                    name="country"
                    defaultValue={user.country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="state" className="text-gray-300 text-sm">
                    State
                  </label>
                  <input
                    name="state"
                    defaultValue={user.state}
                    onChange={(e) => setState(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="deposits" className="text-gray-300 text-sm">
                    Deposits
                  </label>
                  <input
                    name="state"
                    defaultValue={user.deposits}
                    onChange={(e) => setDeposits(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="withdrawals"
                    className="text-gray-300 text-sm"
                  >
                    Withdrawals
                  </label>
                  <input
                    name="state"
                    defaultValue={user.withdrawals}
                    onChange={(e) => setWithdrawals(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="deposited" className="text-gray-300 text-sm">
                    Deposited
                  </label>
                  <input
                    name="deposited"
                    defaultValue={user.deposited}
                    onChange={(e) => setDeposited(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="withdrawn" className="text-gray-300 text-sm">
                    Withdrawn
                  </label>
                  <input
                    name="withdrawn"
                    defaultValue={user.withdrawn}
                    onChange={(e) => setWithdrawn(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="balance" className="text-gray-300 text-sm">
                    Balance
                  </label>
                  <input
                    name="balance"
                    defaultValue={user.balance}
                    onChange={(e) => setBalance(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="activeInvestments"
                    className="text-gray-300 text-sm"
                  >
                    Active Investemnts
                  </label>
                  <input
                    name="activeInvestments"
                    defaultValue={user.activeInvestments}
                    onChange={(e) => setActiveInvestments(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="verification"
                    className="text-gray-300 text-sm"
                  >
                    Verification Status
                  </label>
                  <input
                    name="verification"
                    defaultValue={user.verified}
                    onChange={(e) => setVerification(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="earnings" className="text-gray-300 text-sm">
                    Earnings
                  </label>
                  <input
                    name="earnings"
                    defaultValue={user.earnings}
                    onChange={(e) => setEarnings(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      updateDetails(
                        name,
                        phone,
                        state,
                        country,
                        deposits,
                        deposited,
                        withdrawals,
                        withdrawn,
                        balance,
                        activeInvestments,
                        verification,
                        earnings,
                        user._id,
                        tier
                      );
                    }}
                    type="submit"
                    className="md:w-1/2 w-full bg-[#b2ffce] px-5 py-2 rounded-md font-medium"
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size="25px" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
              <div className="bg-[#181b20] px-5 py-9 my-14 rounded-2xl">
                <h1 className="text-gray-300 text-xl font-semibold mb-3">
                  User Referral Stats
                </h1>
                <div className="flex items-center justify-between">
                  <div>
                    <h1 className="text-gray-400 font-medium mb-2">
                      Registered Users
                    </h1>
                    <h1 className="text-gray-400 font-medium mb-2">
                      {user.refers.length}
                    </h1>
                  </div>
                  <div>
                    <h1 className="text-gray-400 font-medium mb-2">
                      Referral Code
                    </h1>
                    <h1 className="text-gray-400 font-medium mb-2">
                      {user.referralCode}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          )}
          {success === true && (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="success">
                User has been updated successfully
              </Alert>
            </Snackbar>
          )}
          {success === false && (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="error">
                An error occured, please try again later
              </Alert>
            </Snackbar>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPage;
